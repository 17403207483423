import {
  initialize,
  requestConsent,
  addVendor,
  removeVendor
} from '@/util/consent'

class ConsentListener {
  constructor(requestConsentSelector, $context = document) {
    EventBus.on('consent:addCustomVendor', this.onAddCustomVendor.bind(this))
    EventBus.on(
      'consent:removeCustomVendor',
      this.onRemoveCustomVendor.bind(this)
    )
    EventBus.on('consent:requestConsent', this.onRequestConsent.bind(this))
    EventBus.on('consent:consentReady', this.onConsentReady.bind(this))

    $(requestConsentSelector, $context).on(
      'click',
      this.onRequestConsent.bind(this)
    )
  }

  onAddCustomVendor(_event, data) {
    addVendor(data)
  }

  onRemoveCustomVendor(_event, data) {
    removeVendor(data)
  }

  onRequestConsent(_event) {
    requestConsent()
  }

  onConsentReady(_event) {
    global.window.acConsent.resolveEvalVendorsPromise()
  }
}

initialize()

$(() => {
  new ConsentListener('.js-action-privacy-manager')
})
