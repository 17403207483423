import debounce from 'lodash-es/debounce'
import { manualTrack } from '@/vue/directives/tracking'

export default class Header {
  constructor($el) {
    this.$el = $el
    this.$modal = $('.js-headerex__menu-modal', this.$el)
    this.$blur = $('.js-headerex__menu-modal__blur', this.$el)
    this.$toggleOpen = $('.js-headerex__menu--open', this.$el)
    this.$toggleClose = $('.js-headerex__menu--close', this.$el)
    this.open = false

    if (!this.$el || !this.$modal || !this.$blur) {
      return
    }

    this.registerEventHandlers()
  }

  registerEventHandlers() {
    this.$blur.on(
      'click',
      debounce(this.closeMenu, 300, { leading: true, trailing: false }).bind(
        this
      )
    )
    this.$toggleOpen.on(
      'click',
      debounce(this.openMenu, 300, { leading: true, trailing: false }).bind(
        this
      )
    )
    this.$toggleClose.on(
      'click',
      debounce(this.closeMenu, 300, { leading: true, trailing: false }).bind(
        this
      )
    )
  }

  closeMenu(event) {
    this.open = false

    manualTrack(event.currentTarget, {
      event_element: 'burger_menu',
      action_type: 'close'
    })

    // swap buttons
    this.$toggleOpen.removeClass('headerex__menu-toggle--hidden')
    this.$toggleClose.addClass('headerex__menu-toggle--hidden')
    // hide menu
    this.$modal.addClass('headerex__menu-modal--hidden')
    this.$blur.addClass('headerex__menu-modal__blur--hidden')
  }

  openMenu(event) {
    this.open = true

    manualTrack(event.currentTarget, {
      event_element: 'burger_menu',
      action_type: 'open'
    })

    // swap buttons
    this.$toggleOpen.addClass('headerex__menu-toggle--hidden')
    this.$toggleClose.removeClass('headerex__menu-toggle--hidden')
    // show menu
    this.$modal.removeClass('headerex__menu-modal--hidden')
    this.$blur.removeClass('headerex__menu-modal__blur--hidden')
  }
}
