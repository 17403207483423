import GoogleMapsApi from './google_maps_api'
import { registerConsent, requestConsent } from '@/util/consent'
import chunk from 'lodash-es/chunk'

export default class GoogleMap {
  constructor($element, requestConsentSelector, styleClass) {
    this.$element = $element
    this.$missingConsentMarkup = this.$element.html()
    this.requestConsentSelector = requestConsentSelector
    this.companyName = this.$element.data('company')
    this.showInfo = !!this.companyName
    this.query = this.$element.data('query')
    this.type = this.$element.data('type')
    this.$styleClass = styleClass
    this.initialized = false
    this.markers = []
    this.infowindows = []
    this.state = 'init'

    registerConsent({
      vendor: this.type,
      onAdd: this.onConsentAdded.bind(this),
      onRemove: this.onConsentRemoved.bind(this)
    })
  }

  onConsentAdded() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state !== 'rendered') {
      this.state = 'rendered'
      this.initialize()
    }
    this.$element.removeClass(`${this.$styleClass}__map--hidden`)
  }

  onConsentRemoved() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state === 'rendered') {
      this.state = 'restored'
      this.initialized = false
      this.$element.html(this.$missingConsentMarkup)
      $(this.requestConsentSelector, this.$element).on('click', requestConsent)
    }
    this.$element.addClass(`${this.$styleClass}__map--hidden`)
  }

  initialize() {
    if (!this.initialized) {
      this.initMap()
      this.initialized = true
    }
  }

  initMap() {
    const map = new google.maps.Map(this.$element.get(0), {
      zoom: 14
    })

    const locations = typeof this.query === 'string' ? [this.query] : this.query
    const bounds = new google.maps.LatLngBounds()

    const geocoder = new google.maps.Geocoder()

    const locationFunctions = locations.map(location => {
      return () => {
        geocoder
          .geocode({ address: location }, (results, status) => {
            if (status === 'OK') {
              const latLngPosition = results[0].geometry.location
              const infowindow = new google.maps.InfoWindow({
                content:
                  '<p class="google-maps__js-infotext"><b class="google-maps__js-infotext--bold">' +
                  this.companyName +
                  '</b><br>' +
                  location +
                  '</p>'
              })
              const marker = new google.maps.Marker({
                map,
                position: latLngPosition
              })

              this.markers.push(marker)
              this.infowindows.push(infowindow)

              if (locations.length > 1) {
                bounds.extend(latLngPosition)
                map.fitBounds(bounds)
                map.panToBounds(bounds)
                map.fitBounds(bounds)
                map.panToBounds(bounds)
              } else {
                map.setCenter(latLngPosition)
              }

              if (this.showInfo) {
                marker.addListener('click', function () {
                  infowindow.open(map, marker)
                })
              }
            }
          })
          .catch(() => {
            // TODO: capture exception when we have sentry everyhwere
          })
      }
    })

    const delay = 1800
    chunk(locationFunctions, 3).forEach((list, index) => {
      setTimeout(() => {
        list.forEach(func => func())
      }, index * delay)
    })

    this.$element.one(
      'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
      () => {
        google.maps.event.trigger(map, 'resize')

        if (this.showInfo && locations.length === 1) {
          this.infowindows[0].open(map, this.markers[0])
        }
      }
    )
  }
}

let gmapApi

export const bind = ($context = document) => {
  if (!gmapApi) {
    gmapApi = new GoogleMapsApi()
  }
  gmapApi.load().then(() => {
    $('.js-google-map', $context).each((index, Element) => {
      new GoogleMap(
        $(Element),
        '.js-action-privacy-manager',
        'widgets-google-maps'
      )
    })
  })
}

if ($('.js-google-map').length > 0) {
  bind()
}
