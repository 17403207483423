export default class BookmarkIcon {
  constructor($el) {
    this.$el = $el
    this.$iconActive = $('.js-bookmarks-icon-active', this.$el)
    this.$iconInactive = $('.js-bookmarks-icon-inactive', this.$el)

    this.addEventListeners()
  }

  addEventListeners() {
    EventBus.on('bookmarks:count', this.onBookmarksCountSet.bind(this))
  }

  onBookmarksCountSet(_event, count) {
    if (count && count > 0) {
      this.$iconActive.removeClass('hidden')
      this.$iconInactive.addClass('hidden')
    } else {
      this.$iconActive.addClass('hidden')
      this.$iconInactive.removeClass('hidden')
    }
  }
}
