import { registerConsent, requestConsent } from '@/util/consent'

export default class YoutubeVideo {
  constructor($element, requestConsentSelector) {
    this.$element = $element
    this.requestConsentSelector = requestConsentSelector
    this.$missingConsentMarkup = this.$element.html()
    this.code = $element.data('code')
    this.type = $element.data('type')
    this.markup = this.videoMarkup()
    this.state = 'init'

    registerConsent({
      vendor: this.type,
      onAdd: this.onConsentAdded.bind(this),
      onRemove: this.onConsentRemoved.bind(this)
    })
  }

  onConsentAdded() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state !== 'rendered' && !!this.markup) {
      this.state = 'rendered'
      this.$element.html(this.markup)
    }
  }

  onConsentRemoved() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state === 'rendered') {
      this.state = 'restored'
      this.$element.html(this.$missingConsentMarkup)
      $(this.requestConsentSelector, this.$element).on('click', requestConsent)
    }
  }

  videoMarkup() {
    return `<div class='widgets-youtube-video__iframe-wrapper'>
      <iframe src="https://www.youtube-nocookie.com/embed/${this.code}?rel=0" frameborder="0" allowfullscreen></iframe>
    </div>`
  }
}

$(() => {
  $('.js-widgets-youtube-video').each((index, Element) => {
    new YoutubeVideo($(Element), '.js-action-privacy-manager')
  })
})
