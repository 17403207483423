export default class PasswordCheckIdentical {
  constructor($el, $passwordInput, $passwordInputConfirm) {
    this.$el = $el
    this.$passwordInput = $passwordInput
    this.$passwordInputConfirm = $passwordInputConfirm
    this.addEventListeners()
  }

  addEventListeners() {
    this.$passwordInput.on('input', this.onPasswordCheckIdentical.bind(this))
    this.$passwordInputConfirm.on(
      'input',
      this.onPasswordCheckIdentical.bind(this)
    )
  }

  onPasswordCheckIdentical(_event) {
    if (
      this.$passwordInput.val().length > 0 &&
      this.$passwordInput.val() === this.$passwordInputConfirm.val()
    ) {
      this.$el.addClass('card__password-hint--checked')
    } else {
      this.$el.removeClass('card__password-hint--checked')
    }
  }
}
