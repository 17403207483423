import { registerConsent } from '@/util/consent'

export const initVWO = () => {
  registerConsent({
    vendor: 'VWO',
    onAdd: onAddVwoConsent,
    onRemove: onRemoveVwoConsent
  })
  ;(function (window) {
    /* eslint-disable camelcase */
    var wt_vwoConfig = window.wt_vwoConfig || {
      parameterIdVariation: '15'
    }
    /* eslint-disable no-var, one-var, prefer-const, camelcase, no-undef, yoda, no-unused-expressions, no-sequences */
    // prettier-ignore
    window.wt_vwo=function(b){if("page"===b.mode&&"after"===b.type&&1===b.requestCounter){var d=b.instance,k=wt_vwoConfig,l=function(a){if(!a.variationName||!a.testName)return 0;var e="vwo_"+a.testName+"_"+a.testId,f={};f[k.parameterIdVariation]=a.variationName;if("undefined"!==typeof d.vwoTracked[e+"_"+a.variationName])return 0;window.setTimeout(function(){d.sendinfo({linkId:e,customClickParameter:f})},200);d.vwoTracked[e+"_"+a.variationName]=!0},m=function(){for(var a=window._vwo_exp,e=window._vwo_exp_ids,
    f=0,d=e.length;f<d;f++){var b=e[f];if("undefined"!==typeof a[b]&&a[b].ready){var g=a[b],c=g.type.toLowerCase();if("visual_ab"===c||"visual"===c||"split_url"===c)c="","undefined"!==typeof g.combination_chosen&&(c=g.combination_chosen),"undefined"!==typeof g.comb_n[c]&&l({version:"1.0.2",testId:b,testName:g.name,variationId:c,variationName:g.comb_n[c]})}}},h=function(){try{m()}catch(a){}window.setTimeout(function(){h()},500)};(function(){window._vis_opt_queue=window._vis_opt_queue||[];window._vis_opt_queue.push(function(){"undefined"===
    typeof d.vwoTracked&&(d.vwoTracked={},h())})})()}};
    window.wts = window.wts || []
    window.wts.push(['wt_vwo'])
    window.wts = window.wts || []
    window.wts.push(['wt_vwo'])
  })(window)
}

const onAddVwoConsent = () => {
  /* eslint-disable no-var, one-var, prefer-const, camelcase, no-undef */
  // Don't change, provided by VWO, see story AC-3993 for more details
  // prettier-ignore
  window._vwo_code = window._vwo_code || (function() {
    var account_id=709258,
      version = 1.5,
      settings_tolerance=2000,
      library_tolerance=2500,
      use_existing_jquery=false,
      is_spa=1,
      hide_element='body',
      hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
      /* DO NOT EDIT BELOW THIS LINE */
      f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());
  /* eslint-enable no-var, one-var, prefer-const, camelcase, no-undef */
}

const onRemoveVwoConsent = () => {
  // The VWO impl. is a black box, so not much we can do when consent revoked
  window._vwo_code = undefined
  window._vwo_settings_timer = undefined
}

export const bind = () => {
  return initVWO
}

global.initVWO = bind()
