import PasswordCheckAtLeast from './password_check_at_least'
import PasswordCheckIdentical from './password_check_identical'
import PasswordVisibleSwitch from './password_visible_switch'

export const bindAll = $doc => {
  if ($doc.find('.js_password-switch-input').length > 0) {
    $doc.find('.card__password-hint').removeClass('hidden')
  }

  if ($doc.find('.js-password-check-at-least').length > 0) {
    $doc.find('.js-password-check-at-least').each(function () {
      new PasswordCheckAtLeast(
        $(this),
        $doc.find('.js_password-switch-input').first()
      )
    })
  }

  if ($doc.find('.js-password-check-identical').length > 0) {
    $doc.find('.js-password-check-identical').each(function () {
      new PasswordCheckIdentical(
        $(this),
        $doc.find('.js_password-switch-input').first(),
        $doc.find('.js_password-switch-input').last()
      )
    })
  }

  if ($doc.find('.js_password-switch-button').length > 0) {
    new PasswordVisibleSwitch(
      $doc.find('.js_password-switch-button'),
      $doc.find('.js_password-switch-input')
    )
  }
}

$(() => {
  bindAll($(document))
})
