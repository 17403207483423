class EventBus {
  on(eventName, fn) {
    $(this).bind(eventName, fn)
  }

  off(eventName, fn) {
    $(this).unbind(eventName, fn)
  }

  trigger(eventName, data) {
    $(this).trigger(eventName, data)
  }

  clear() {
    $(this).off()
  }
}

const eventBus = new EventBus()

// window.Athene.EventBus = eventBus
global.EventBus = eventBus

export default eventBus
