// https://stackoverflow.com/questions/6421769/popstate-on-pages-load-in-chrome
// https://academics-akra.atlassian.net/browse/AC-419

;(function () {
  // There's nothing to do for older browsers ;)
  if (!window.addEventListener) {
    return
  }
  let blockPopstateEvent = document.readyState !== 'complete'
  window.addEventListener(
    'load',
    function () {
      // The timeout ensures that popstate-events will be unblocked right
      // after the load event occured, but not in the same event-loop cycle.
      setTimeout(function () {
        blockPopstateEvent = false
      }, 0)
    },
    false
  )
  window.addEventListener(
    'popstate',
    function (evt) {
      if (blockPopstateEvent && document.readyState !== 'complete') {
        evt.preventDefault()
        evt.stopImmediatePropagation()
      }
    },
    false
  )
})()
