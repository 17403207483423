export default class TagSink {
  constructor($el, renderPill, pillSelector, removeSelector, eventNameSpace) {
    this.$el = $el
    this.renderPill = renderPill
    this.pillSelector = pillSelector
    this.removeSelector = removeSelector
    this.eventNameSpace = eventNameSpace

    this.tags = []
    this.renderTimeout = null
    this.render = this.render.bind(this)
    this.addEventHandlers()
  }

  addEventHandlers() {
    EventBus.on(`${this.eventNameSpace}:add`, this.onAdd.bind(this))
    EventBus.on(
      `${this.eventNameSpace}:new_profile`,
      this.onNewProfile.bind(this)
    )
    EventBus.on(`${this.eventNameSpace}:remove`, this.onRemove.bind(this))
    EventBus.on(`${this.eventNameSpace}:reset`, this.onReset.bind(this))

    this.$el.on('click', this.removeSelector, this.onPillClick.bind(this))
  }

  onPillClick(event) {
    EventBus.trigger(`${this.eventNameSpace}:remove`, {
      key: '' + $(event.currentTarget).parents(this.pillSelector).data('key')
    })
  }

  onNewProfile(_event, profile) {
    this.tags = profile.tags.concat(profile.searchTerms)
    this.render()
  }

  onReset(_event, _data) {
    this.tags.forEach(tag => {
      // Do this async so we do not mutate `this.tags` while looping over it
      setTimeout(() => {
        EventBus.trigger(`${this.eventNameSpace}:remove`, tag)
      })
    })
  }

  onAdd(_event, data) {
    const tagIndex = this.tags.findIndex(tag => tag.key === data.key)

    if (tagIndex === -1) {
      this.tags.push(data)
      this.render()
    }
  }

  onRemove(_event, data) {
    const tagIndex = this.tags.findIndex(tag => tag.key === data.key)

    if (tagIndex !== -1) {
      this.tags.splice(tagIndex, 1)
      this.render()
    }

    EventBus.trigger(`${this.eventNameSpace}:show`, false)
  }

  render() {
    if (this.renderTimeout) {
      clearTimeout(this.renderTimeout)
    }
    this.renderTimeout = setTimeout(() => {
      this.$el.html(
        this.tags
          .map(tag =>
            this.renderPill(tag, 'js-tag-sink__pill search-profile__pill')
          )
          .join('')
      )
      this.renderTimeout = null
    }, 10)
  }
}
