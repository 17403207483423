export default class CategoryTag {
  constructor($el, eventNameSpace) {
    this.$el = $el
    this.eventNameSpace = eventNameSpace
    this.key = this.$el.attr('value')
    this.label = this.$el.data('label')
    this.checked = false
    this.render = this.render.bind(this)

    this.addEventHandlers()
    this.load()
  }

  addEventHandlers() {
    EventBus.on(`${this.eventNameSpace}:add`, this.onAdd.bind(this))
    EventBus.on(`${this.eventNameSpace}:remove`, this.onRemove.bind(this))
    this.$el.on('click', event => {
      const kind = this.checked ? 'remove' : 'add'
      this.triggerEvent(kind)
    })
  }

  onAdd(_event, data) {
    if (data.key === this.key) {
      this.checked = true
      this.render()
    }
  }

  onRemove(_event, data) {
    if (data.key === this.key) {
      this.checked = false
      this.render()
    }
  }

  load() {
    if (this.$el.prop('checked')) {
      this.triggerEvent('add')
    }
  }

  triggerEvent(kind) {
    EventBus.trigger(`${this.eventNameSpace}:${kind}`, {
      key: this.key,
      label: this.label
    })
  }

  render() {
    this.$el.prop('checked', this.checked)
  }
}
