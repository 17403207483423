/**
 * Utility functions for scrollable elements
 */

export const caretAtStart = el => {
  try {
    return el.selectionStart === 0 && el.selectionEnd === 0
  } catch (e) {
    return el.value === ''
  }
}

export const hasOverflowLeft = $el => {
  return $el.get(0).scrollLeft !== 0
}

export const hasOverflowRight = $el => {
  return $el.innerWidth() + $el.scrollLeft() < $el.get(0).scrollWidth
}

export const scrollIntoView = (el, container, padding = 30) => {
  // Check if already in view
  if (
    !(
      container.scrollLeft <= el.offsetLeft &&
      container.scrollLeft + $(container).innerWidth() >=
        el.offsetLeft + $(el).innerWidth()
    )
  ) {
    container.scrollLeft = Math.max(
      el.offsetLeft - container.offsetLeft - padding,
      0
    )
  }
}
