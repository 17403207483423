import debounce from 'lodash-es/debounce'
export default class RemoveSearchProfile {
  constructor($el) {
    this.$el = $el
    this.url = this.$el.data('remove-url')
    this.title = this.$el.data('title')
    this.profile = null
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.$el.find('.js-search-profile-summary__delete').on(
      'click',
      debounce(
        $event => {
          $event.preventDefault()
          this.removeProfile()
        },
        500,
        { leading: true, trailing: false }
      )
    )
  }

  removeProfile() {
    $.ajax({
      dataType: 'json',
      url: this.url,
      method: 'delete'
    }).done(data => {
      this.profile = data.profile
      EventBus.trigger('search-profile:refresh', null)
      EventBus.trigger('snackbar:message', {
        body: data.snackbar,
        handlers: [
          {
            selector: '.js-snackbar__button',
            eventName: 'click',
            handler: this.onUndo.bind(this)
          }
        ]
      })
    })
  }

  onUndo() {
    EventBus.trigger('snackbar:hide', null)
    EventBus.trigger('search-profile:create', this.profile)
    EventBus.trigger('tracking:manualEventTrack', {
      action_type: 'search_profile_delete_undo',
      $elem: this.$el,
      context: { content_element: 'snackbar' }
    })
  }
}
