import Cookies from 'js-cookie'

export default class RegistrationFence {
  constructor($el) {
    const parsedDelay = Number.parseInt($el.data('delay'), 10)
    this.$el = $el
    this.url = $el.data('url')
    this.delay = isNaN(parsedDelay) ? 0 : parsedDelay * 1000
    this.keepClosed = false
    this.addEventHandlers()
    this.open()
  }

  addEventHandlers() {
    EventBus.on('sessionModal:open', this.onSessionModalOpened.bind(this))
    this.$el.on(
      'click',
      '.js-registration-fence-close',
      this.onClose.bind(this)
    )
    this.$el.on(
      'click',
      '.js-registration-fence-overlay',
      this.onOverlayClick.bind(this)
    )
    EventBus.on('registration_fence:open', (_event, url) => this.open(0, url))
  }

  onSessionModalOpened(_event) {
    this.keepClosed = true
    this.$el.hide()
  }

  onClose() {
    this.$el.fadeOut()
    Cookies.set('registration_fence_uneligible', 'true', {
      expires: 7,
      path: '/'
    })
    EventBus.trigger('registration_fence:closed', true)
  }

  onOverlayClick($event) {
    if ($event.currentTarget === $event.target) {
      $('.js-registration-fence-close', this.$el).click()
    }
  }

  open(delay = this.delay, url) {
    $.get(url || this.url, data => {
      this.$el.hide()
      this.$el.delay(delay).fadeIn(() => {
        if (!this.keepClosed) {
          this.$el.html(data)
          EventBus.trigger('tracking:manualEventTrack', {
            action_type: 'appear',
            $elem: this.$el.children().first()
          })
          EventBus.trigger('registration_fence:opened', true)
          $('.input--login', this.$el).focus()
        }
      })
    })
  }
}

export const bind = () => {
  const $elem = $('.js-registration-fence')
  if ($elem.length > 0) {
    new RegistrationFence($('.js-registration-fence'))
  }
}

bind()
