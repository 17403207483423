import Header from './header'

const bind = ($context = document) => {
  $(() => {
    $('.js-headerex__menu', $context).each(function () {
      new Header($(this))
    })
  })
}

bind()
