import { AllTag, CategoryTag, SearchTerms, TagSink } from '@/indexing'
import RemoveSearchProfile from '@/search_profiles/remove_search_profile'
import ToggleSearchProfile from '@/search_profiles/toggle_search_profile'
import BulkUpdateSearchProfileState from '@/search_profiles/bulk_update_search_profile_state'
import Summaries from '@/search_profiles/summaries'
import renderPill from '@/util/pill'
import Modal from '@/search_profiles/modal'

export default ($context = document) => {
  $(() => {
    const eventNameSpace = 'search-profile-tag'

    $('.js-search-profile__reset-tags', $context).on('click', function (event) {
      event.preventDefault()
      EventBus.trigger('search-profile-tag:reset', null)
    })

    $('.js-search-profile__accordion-title', $context).on(
      'click',
      function (event) {
        const id = $(this).attr('for')
        const $target = $(`#${id}`)
        if ($target.prop('checked')) {
          event.preventDefault()
          $target.prop('checked', false)
        }
      }
    )

    $('.js-search-profile__tags-container', $context).each(function () {
      new TagSink(
        $(this),
        renderPill,
        '.js-tag-sink__pill',
        '.js-search-input__remove',
        eventNameSpace
      )
    })

    $('.js-search-profile__tag-checkbox-all', $context).each(function () {
      new AllTag($(this), '.js-search-profile__tag-checkbox', eventNameSpace)
    })

    $('.js-search-profile__tag-checkbox', $context).each(function () {
      new CategoryTag($(this), eventNameSpace)
    })

    $('.js-search-profile-summary', $context).each(function () {
      new RemoveSearchProfile($(this))
    })

    $('.js-search-profile-summary', $context).each(function () {
      new ToggleSearchProfile($(this), '.js-search-profile-summary')
    })

    $('.js-search-profiles', $context).each(function () {
      new BulkUpdateSearchProfileState(
        $(this),
        '.js-search-profile-summaries__bulk-update',
        '.js-search-profile-summary__toggle'
      )
    })

    $('.js-search-profile-summaries', $context).each(function () {
      new Summaries($(this))
    })

    $('.js-search-term', $context).each(function () {
      new SearchTerms($(this), '.js-search-term', eventNameSpace)
    })

    $('.js-open-modal', $context).each(function () {
      new Modal($(this))
    })
  })
}
