import debounce from 'lodash-es/debounce'
const FADE_SPEED = 300

export default class BulkUpdateSearchProfileState {
  constructor($el, bulkUpdateInputSelector, singleInputSelector) {
    this.$el = $el
    this.$bulkInput = this.$el.find(bulkUpdateInputSelector)
    this.url = this.$bulkInput.data('bulk-update-url')
    this.intentLabels = {
      activate: this.$bulkInput.data('bulk-update-activate-label'),
      deactivate: this.$bulkInput.data('bulk-update-deactivate-label')
    }
    this.trackingValues = {
      activate: this.$bulkInput.data('bulk-update-activate-tracking-value'),
      deactivate: this.$bulkInput.data('bulk-update-deactivate-tracking-value')
    }
    this.singleInputSelector = singleInputSelector
    this.updateIntent = {
      isActive: this.countActiveCheckboxes() === 0
    }
    this.$bulkInput.text(
      this.intentLabels[this.updateIntent.isActive ? 'activate' : 'deactivate']
    )
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.$bulkInput.on(
      'click',
      debounce(() => {
        this.bulkUpdateProfiles()
        this.updateElement()
      }, 300)
    )

    EventBus.on('search-profile:update-state', _event => {
      this.synchronizeWithSingleInputs()
    })
  }

  synchronizeWithSingleInputs() {
    const hasNoneActive = this.countActiveCheckboxes() === 0
    if (this.updateIntent.isActive !== hasNoneActive) {
      this.updateIntent.isActive = hasNoneActive
      this.updateElement()
    }
  }

  countActiveCheckboxes() {
    return this.$el.find(`${this.singleInputSelector}:checked`).length
  }

  updateElement() {
    const currentIntentLabel =
      this.intentLabels[this.updateIntent.isActive ? 'activate' : 'deactivate']
    if (this.$bulkInput.text() !== currentIntentLabel) {
      this.$bulkInput.fadeOut(FADE_SPEED, () => {
        this.$bulkInput.text(currentIntentLabel)
        const newTrackingValue = this.getNewTrackingAttrValue()
        this.$bulkInput.attr('data-ac-click', newTrackingValue)
        this.$bulkInput.data('ac-click', JSON.parse(newTrackingValue))
        this.$bulkInput.fadeIn(FADE_SPEED)
      })
    }
  }

  getNewTrackingAttrValue() {
    const newTrackingValue =
      this.trackingValues[
        this.updateIntent.isActive ? 'activate' : 'deactivate'
      ]
    const trackingAttrValue = this.$bulkInput.attr('data-ac-click')
    const oldTrackingValue = trackingAttrValue.includes(
      this.trackingValues.activate
    )
      ? this.trackingValues.activate
      : this.trackingValues.deactivate
    return trackingAttrValue.replace(oldTrackingValue, newTrackingValue)
  }

  bulkUpdateProfiles() {
    $.ajax({
      dataType: 'json',
      url: this.url,
      method: 'post',
      data: { is_active: this.updateIntent.isActive }
    }).then(response => {
      if (response && response.isActive !== null) {
        EventBus.trigger(
          'search-profiles:bulk-update-state',
          !!response.isActive
        )
        this.updateIntent = { isActive: !response.isActive }
        this.updateElement()
      }
    })
  }
}
