import summaryBind from '@/search_profiles/bind'
import bookmarkBind from '@/bookmarks/bind'

const FADE_SPEED = 300

export default class Summaries {
  constructor($el) {
    this.$el = $el
    this.addEventHandlers()
  }

  addEventHandlers() {
    EventBus.on('search-profile:create', this.onCreate.bind(this))
    EventBus.on('search-profile:refresh', this.onRefresh.bind(this))
  }

  onCreate(_event, data) {
    $.ajax({
      url: this.$el.data('create-url'),
      data,
      method: 'post'
    }).done(this.onRefresh.bind(this))
  }

  onRefresh(_event, _data) {
    this.$el.fadeTo(FADE_SPEED, 0)
    $.ajax({
      url: this.$el.data('refresh-url'),
      method: 'get'
    })
      .done(result => {
        const content = $(result).find('.js-search-profile-summaries').html()
        this.$el.html(content)
        summaryBind(this.$el)
        bookmarkBind(this.$el)
        EventBus.trigger('bookmarks:replay')
        EventBus.trigger('vue:mount')
      })
      .always(() => {
        this.$el.fadeTo(FADE_SPEED, 1)
      })
  }
}
