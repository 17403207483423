import escape from 'lodash-es/escape'
import { registerConsent, requestConsent } from '@/util/consent'

export default class SocialFeed {
  constructor($element, requestConsentSelector) {
    this.$element = $element
    this.requestConsentSelector = requestConsentSelector
    this.$missingConsentMarkup = this.$element.html()
    this.url = $element.data('url')
    this.type = $element.data('type')

    if (this.type === 'FACEBOOK') {
      this.markup = this.facebookMarkup()
    } else if (this.type === 'TWITTER') {
      this.markup = this.twitterMarkup()
    }
    this.state = 'init'

    registerConsent({
      vendor: this.type,
      onAdd: this.onConsentAdded.bind(this),
      onRemove: this.onConsentRemoved.bind(this)
    })
  }

  onConsentAdded() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state !== 'rendered' && !!this.markup) {
      this.state = 'rendered'
      this.$element.html(this.markup)
    }
  }

  onConsentRemoved() {
    if (this.state === 'init') {
      this.$element.removeClass('invisible')
    }
    if (this.state === 'rendered') {
      this.state = 'restored'
      this.$element.html(this.$missingConsentMarkup)
      $(this.requestConsentSelector, this.$element).on('click', requestConsent)
    }
  }

  twitterMarkup() {
    return `<a class="twitter-timeline" data-height="500" href="${escape(
      this.url
    )}" />
      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
  }

  facebookMarkup() {
    const width = 500
    const url = `https://www.facebook.com/plugins/page.php?href=${escape(
      this.url
    )}&tabs=timeline&width=${width}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`

    return `<iframe
        src="${url}"
        width=${width}
        height="500"
        class="widgets-social_feed__facebook"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"
      /> `
  }
}

$(() => {
  $('.js-widgets-social_feed').each((index, Element) => {
    new SocialFeed($(Element), '.js-action-privacy-manager')
  })
})
