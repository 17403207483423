import slug from '../util/slug'

export default class Subnavigation {
  constructor($el, $headlines) {
    this.$el = $el
    this.$headlines = $headlines
    this.buildSubnavigation()
  }

  buildSubnavigation() {
    const headlineMap = {}
    this.$headlines.each((_idx, elem) => {
      const $headline = $(elem).text()
      const idText = 'subnav_' + slug($headline)
      headlineMap[idText] = $headline
      $(elem).attr('id', idText)
    })
    $.each(headlineMap, (id, text) => {
      const li = `<li class="widget-subnavigation__list-item"><a href="#${id}">${text}</a></li>`
      this.$el.append(li)
    })
  }
}
