import { mapConfig } from '@/webtrekk_tracking/config'
import { getCurrentSearchContext } from '@/util/search_context'
import { hasConsent, registerConsent } from '@/util/consent'
import { loadScript } from '@/util/load_script'
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'
import mergeDeep from 'lodash-es/merge'
import cloneDeep from 'lodash-es/cloneDeep'

const ACADEMICS_DOMAIN = 'academics'
const MAILTO_PREFIX = 'mailto:'
const VENDOR = 'WEBTREKK'

export default class WebtrekkTracking {
  constructor(config) {
    this.config = this.isZonApp() ? { ...config, sendViaSDK: true } : config
    this.initialPixelsConfig = null
    this.initialPageTrackSent = false
    this.wts = []
    let resolveWebtrekkUrlPromise = null
    this.webtrekkUrlPromise = new Promise((resolve, _reject) => {
      resolveWebtrekkUrlPromise = resolve
    })
    this.resolveWebtrekkUrlPromise = resolveWebtrekkUrlPromise
    this.addEventListeners()
    registerConsent({
      vendor: VENDOR,
      onAdd: this.sendInitialPageTrack.bind(this)
    })
  }

  init(initialPixelsConfig, webtrekkUrl) {
    this.initialPixelsConfig = initialPixelsConfig
    this.resolveWebtrekkUrlPromise(webtrekkUrl)
    return this.sendInitialPageTrack()
  }

  addEventListeners() {
    EventBus.on('tracking:pageload', this.onResults.bind(this))
    EventBus.on('tracking:registrationSuccess', this.onResults.bind(this))
    EventBus.on('tracking:manualEventTrack', this.onManualEventTrack.bind(this))
    EventBus.on('tracking:vueEventTrack', this.onVueEventTrack.bind(this))
    $(document).on(
      'click',
      'a [data-ac-click],a[data-ac-click],button[data-ac-click],label[data-ac-click],input[data-ac-click],span[data-ac-click]',
      this.onClickTrack.bind(this)
    )
    $(document).on(
      'click',
      'a[data-klickout]',
      this.onJobwareClickTrack.bind(this)
    )
  }

  callAfterWebtrekkLoaded(callback) {
    return hasConsent(VENDOR).then(
      hasIt =>
        hasIt &&
        this.webtrekkUrlPromise.then(webtrekkUrl =>
          loadScript(webtrekkUrl).then(() => callback())
        )
    )
  }

  sendInitialPageTrack() {
    return this.callAfterWebtrekkLoaded(() => {
      // Do not send initial page track twice if user toggles consent on/off/on
      if (this.initialPageTrackSent) {
        return
      }
      this.wts = this.createPixels(this.initialPixelsConfig)
      this.wts.forEach(wt => wt.sendinfo())
      this.initialPageTrackSent = true
    })
  }

  onResults(_event, { pixelsConfig, referrer }) {
    this.track(pixelsConfig, referrer)
  }

  onClickTrack(event) {
    if (!event.originalEvent || !event.originalEvent.tracked) {
      if (event.originalEvent) {
        event.originalEvent.tracked = true
      }

      const $target = $(event.currentTarget)
      const params = $.extend(
        this.trackingContext($target),
        $target.data('ac-click')
      )
      // google maps toggle map is handled by a checkbox
      if ($target.attr('type') === 'checkbox') {
        params[9] = $target.is(':checked')
          ? `${params[9]}_on`
          : `${params[9]}_off`
      }
      this.trackClick($target, params)
    }
  }

  onManualEventTrack(_event, { $elem, action_type: actionType, context }) {
    let params
    if (context) {
      params = { ...mapConfig(context), 9: actionType }
    } else {
      params = { 9: actionType }
    }
    this.trackClick($elem, $.extend(this.trackingContext($elem), params))
  }

  onVueEventTrack(event, { el, config }) {
    const $elem = $(el)
    const params = $.extend(this.trackingContext($elem), config)
    this.trackClick($elem, params)
  }

  onJobwareClickTrack(event) {
    const $target = $(event.currentTarget)
    const params = $.extend(this.trackingContext($target), {
      9: $target.data('klickout')
    })
    this.trackClick($target, params)
  }

  trackingContext($element) {
    // addBack() replaces deprecated andSelf()
    // both reverse the order from outer element to inner element
    // since we want inner context to "win" we have to merge onto the accumulator in reduce()
    return $element
      .parents('[data-ac-context]')
      .addBack()
      .map(function () {
        return $(this).data('ac-context') || {}
      })
      .toArray()
      .reduce((acc, obj) => $.extend(acc, obj), {})
  }

  trackClick($element, params) {
    return this.callAfterWebtrekkLoaded(() => {
      params[4] = MediaQuery.current // Set breakpoint parameter ck4
      if (params.cep) {
        params[6] = `${params[6]}|${params.cep}`
      }

      this.wts
        .filter(wt => wt.pixelDomain === ACADEMICS_DOMAIN)
        .forEach(wt => {
          wt.setReferrer(this.referrer($element))
          wt.sendinfo({
            linkId: this.filterUserData($element, params),
            customClickParameter: params,
            sendOnUnload: this.shouldSendOnUnload($element)
          })
        })
    })
  }

  filterUserData($element, params) {
    const linkId = $element.attr('href') || params.ct || '#'
    return linkId.indexOf(MAILTO_PREFIX) === 0 ? 'mailto' : linkId
  }

  shouldSendOnUnload($element) {
    const target = $element.attr('target') || ''
    return target === '_blank' ? 0 : 1
  }

  createPixels(pixelsConfig) {
    if (!pixelsConfig) {
      return
    }

    const wts = []
    Object.keys(pixelsConfig).forEach(domain => {
      try {
        const wt = new webtrekkV3(this.config)
        Object.keys(pixelsConfig[domain]).forEach(key => {
          wt[key] = pixelsConfig[domain][key]
        })

        wt.customParameter[12] = this.agentType()
        if (domain === ACADEMICS_DOMAIN) {
          wt.customParameter[20] = this.pageOrientation()

          const contextCode = getCurrentSearchContext()
          if (contextCode) {
            wt.customParameter[43] = contextCode
          } else {
            delete wt.customParameter[43]
          }
        }

        wt.pixelDomain = domain
        wts.push(wt)
      } catch (e) {
        // TODO; gather metrics for failed attempts to create webtrekk pixel
      }
    })
    return wts
  }

  track(pixelsConfig, referrer = null) {
    return this.callAfterWebtrekkLoaded(() => {
      const mergedConfig = mergeDeep(
        cloneDeep(this.initialPixelsConfig),
        pixelsConfig
      )
      this.createPixels(mergedConfig).forEach(wt => {
        wt.setReferrer(referrer || document.referrer)
        wt.sendinfo()
      })
    })
  }

  pageOrientation() {
    if (window.innerHeight > window.innerWidth) {
      return 'portrait'
    } else {
      return 'landscape'
    }
  }

  agentType() {
    if (this.isZonApp()) {
      return 'zonapp'
    } else if (MediaQuery.current === 'small') {
      return 'mobile.site'
    } else {
      return 'desktop.site'
    }
  }

  isZonApp() {
    return /zonapp\/[^/]+\/(?!1\.)/.test(navigator.userAgent.toLowerCase())
  }

  // If a vue component changes url without reloading the page, the referrer will have to be set manually for tracking
  // In such case new value of referrer is set in vuex store and has to be retrieved from there for tracking
  referrerFromVueStore(element) {
    const vue =
      element instanceof jQuery && element.context
        ? element.context.__vue__
        : element.__vue__
    return vue && vue.$store.state.referrer
  }

  referrer(element) {
    return this.referrerFromVueStore(element) || document.referrer
  }
}

export const bind = () => {
  const pageconfig = {
    linkTrack: 'standard',
    linkTrackAttribute: 'data-wt-click'
  }

  MediaQuery._init()

  return new WebtrekkTracking(pageconfig)
}

global.WebtrekkTracking = bind()
