export default class BookmarkListEmpty {
  constructor($el) {
    this.$el = $el
    this.addEventListeners()
  }

  addEventListeners() {
    EventBus.on('bookmarks:empty', this.onBookmarkEmpty.bind(this))
    EventBus.on('bookmarks:added', this.onBookmarkAdded.bind(this))
  }

  onBookmarkEmpty(_event) {
    return this.$el.fadeIn()
  }

  onBookmarkAdded(_event) {
    this.$el.fadeOut()
  }
}
