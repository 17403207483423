export default class BookmarkItem {
  constructor($el) {
    this.$el = $el
    this.$link = $('.js-bookmark-link', this.$el)
    this.searchContext = this.readSearchContext(this.$link.attr('href'))
    this.jobId = parseInt(this.$el.data('jobId'))
    this.addEventListeners()
  }

  addEventListeners() {
    EventBus.on('bookmarks:removed', this.onBookmarkRemoved.bind(this))
    EventBus.on('bookmarks:added', this.onBookmarkAdded.bind(this))
  }

  onBookmarkAdded(_event, { jobId, searchContext }) {
    if (searchContext && this.searchContext !== searchContext) {
      this.updateLink(searchContext)
    }
    if (jobId === this.jobId) {
      this.$el.fadeIn()
    }
  }

  onBookmarkRemoved(_event, { jobId, searchContext }) {
    if (jobId === this.jobId) {
      this.$el.fadeOut()
    } else if (searchContext) {
      this.updateLink(searchContext)
    }
  }

  readSearchContext(url) {
    if (!url) {
      return ''
    }
    const anchorIdx = url.indexOf('#')
    return anchorIdx !== -1 ? url.substring(anchorIdx + 1) : ''
  }

  updateLink(searchContext) {
    const url = this.$link.attr('href')
    if (url) {
      this.$link.attr(
        'href',
        url.replace(`#${this.searchContext}`, `#${searchContext}`)
      )
    }
  }
}
