export default class SearchToggle {
  constructor($search, $searchToggle) {
    $searchToggle.on('click', event => {
      event.preventDefault()

      if ($search.hasClass($search.data('toggle-class'))) {
        $search.hide()
        $search.removeClass($search.data('toggle-class'))
        $search.slideDown()
      } else {
        $search.slideUp(() => {
          $search.addClass($search.data('toggle-class'))
          $search.removeAttr('style')
        })
      }
    })
  }
}

$(() => {
  new SearchToggle($('.js-search'), $('.js-toggle-search'))
})
