import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'
import { waitForSourcePointConsent } from '@/util/consent'
import { loadScript } from '@/util/load_script'

export default class IVWTracking {
  constructor() {
    // referenced in https://script.ioam.de/iam.js:357 #transmitData()
    // default mode 0 will trigger a document.write() which replaces all markup if called after a page finished loading
    // we append this script based on user consent, so we have to switch mode passed to #count() :557
    this.iamMode = 1
    this.readyToTrack = false
    this.trackSentIom = false
    this.trackSentMeasurmentManager = false
  }

  sendConfig(config, stage) {
    if (!config) {
      return
    }

    const st = MediaQuery.current === 'small' ? 'mobzeit' : 'zeitonl'
    config.iom.sv =
      config.iom.sv === 'ke'
        ? 'ke'
        : MediaQuery.current === 'small'
        ? 'mo'
        : 'in'
    config.iom.st = st
    config.measurementManager.configure.st = st
    this.stage = stage
    this.config = config

    return waitForSourcePointConsent()
      .then(() => {
        this.readyToTrack = true
      })
      .then(() => this.load())
      .catch(() => {}) // do nothing and ignore errors
  }

  /* eslint-disable multiline-ternary, prefer-promise-reject-errors */
  load() {
    const canNotLoad =
      (this.trackSentIom && this.trackSentMeasurmentManager) ||
      !this.readyToTrack ||
      !this.config
    if (canNotLoad) {
      return Promise.reject()
    }

    if (this.stage !== 'prod') {
      this.trackSentIom = true
      this.trackSentMeasurmentManager = true
      console.log('IVW added:', { config: this.config, stage: this.stage })
      return Promise.resolve()
    }

    const domain = this.config.measurementManager.configure.dn
    return Promise.allSettled([
      this.trackSentIom
        ? Promise.reject()
        : Promise.resolve()
            .then(() => loadScript('https://script.ioam.de/iam.js'))
            .then(() => !(window.iom && window.iom.c) && Promise.reject())
            .then(() => {
              window.iom.c(this.config.iom, this.iamMode)
              this.trackSentIom = true
            })
            .catch(_response => {}), // ignore errors while loading or executing tracking scripts
      this.trackSentMeasurmentManager
        ? Promise.reject()
        : Promise.resolve()
            .then(() =>
              loadScript(`https://${domain}/iomm/latest/bootstrap/stub.js`)
            )
            .then(() =>
              loadScript(
                `https://${domain}/iomm/latest/manager/base/es5/bundle.js`
              )
            )
            .then(() => !window.IOMm && Promise.reject())
            .then(() => {
              window.IOMm('configure', this.config.measurementManager.configure)
              window.IOMm('pageview', this.config.measurementManager.pageview)
              this.trackSentMeasurmentManager = true
            })
            .catch(_response => {}) // ignore errors while loading or executing tracking scripts
    ])
  }

  /* eslint-enable multiline-ternary, prefer-promise-reject-errors */
}

export const bind = () => {
  MediaQuery._init()
  return new IVWTracking()
}

global.IVWTracking = bind()
