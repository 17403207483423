import charMap from './charmap'

export default string => {
  let result = ''
  for (let char, i = 0, length = string.length; i < length; i++) {
    char = string[i]
    if (charMap[char]) {
      char = charMap[char]
    }
    char = char.replace(/[^\w\s\-._]/g, '')
    result += char
  }
  result = result.replace(/\s+/g, '_')
  return result.toLowerCase()
}
