import pillTemplate from '@/util/pill'
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'
import jump from 'jump.js-cancelable'
import {
  ENTER_KEYCODE,
  ESCAPE_KEYCODE,
  LEFT_KEYCODE,
  RIGHT_KEYCODE,
  DELETE_KEYCODE,
  BACKSPACE_KEYCODE
} from '@/util/keycodes'
import {
  caretAtStart,
  hasOverflowLeft,
  hasOverflowRight,
  scrollIntoView
} from '@/search/scroll'
import { bind as awesompleteBind } from '@/awesomplete_wrapper'
import TypeAhead from '@/search/type_ahead'
import { manualTrack } from '@/vue/directives/tracking'

export default class SearchInput {
  constructor($el, selectorPrefix) {
    this.selectedIndex = null
    this.selectorPrefix = selectorPrefix
    this.$el = $el
    this.$wrapper = this.$el.find(`.${selectorPrefix}__wrapper`)
    this.$inner = this.$el.find(`.${selectorPrefix}__inner`)
    this.$input = this.$el.find(`.${selectorPrefix}__input`)
    this.$hidden = this.$el.find(`.${selectorPrefix}__hidden`)
    this.$tags = this.$el.find(`.${selectorPrefix}__pills`)
    this.scrollAnchor = this.$el.find(
      `.${selectorPrefix}__focus-scroll-anchor, .${selectorPrefix}__focus-scroll-anchor--fused`
    )[0]
    this.scrollOffset = this.scrollOffset(this.scrollAnchor.classList)

    this.overflowLeftClass = this.$wrapper.data('overflow-left')
    this.overflowRightClass = this.$wrapper.data('overflow-right')
    this.selectedPillClass = this.$wrapper.data('selected-pill')
    this.skipManualTrack = !!this.$wrapper.data('skip-manual-track')

    this.typedInput = ''

    this.tags = new Set()
    this.addEventHandlers()
    this.jobMarket = this.$input.data('job-market')
    this.splitSpaces = this.$input.data('split-spaces')
    this.awesompleteWrapper = awesompleteBind()
    this.typeAhead = new TypeAhead(
      this.awesompleteWrapper.awesomplete,
      this.$input.data('typeahead-url')
    )
  }

  addEventHandlers() {
    this.$el.on('submit', this.onSubmit.bind(this))

    this.$inner.on('scroll', this.onScroll.bind(this))

    this.$input.on('input', this.onInput.bind(this))
    this.$input.on('keydown', this.onKeydown.bind(this))
    this.$input.on('click', this.unselect.bind(this))

    if (this.scrollOffset != null) {
      this.$input.on('focus', this.onFocus.bind(this))
    }

    this.$tags.on(
      'click',
      `.${this.selectorPrefix}__pill`,
      this.onTagClick.bind(this)
    )
    this.$tags.on(
      'click',
      `.${this.selectorPrefix}__remove`,
      this.onTagRemove.bind(this)
    )

    EventBus.on('awesomplete_wrapper:select', this.onTypeAheadSelect.bind(this))
    EventBus.on('search:reset', this.reset.bind(this))
  }

  unselect() {
    this.selectedIndex = null
    this.renderSelected()
  }

  onFocus(_event) {
    if (MediaQuery.is('small only')) {
      jump.jump(this.scrollAnchor, { offset: this.scrollOffset })
    }
  }

  scrollOffset(classList) {
    if (
      classList.contains(`${this.selectorPrefix}__focus-scroll-anchor--fused`)
    ) {
      return -70
    } else if (
      classList.contains(`${this.selectorPrefix}__focus-scroll-anchor`)
    ) {
      return -10
    } else {
      return null
    }
  }

  onInput(event) {
    this.typedInput = this.$input.val()
    this.splitInputToTags(event)
    this.typeAhead.handleTypeAhead(this.$input.val(), this.jobMarket)
  }

  splitInputToTags(event) {
    // If there's no comma, we don't need to do anything
    if (
      this.typedInput.indexOf(',') === -1 &&
      (!this.splitSpaces || this.typedInput.indexOf(' ') === -1)
    ) {
      return
    }

    const regex = this.splitSpaces ? /[\s,]/ : /,/
    const tags = this.typedInput
      .split(regex)
      .map(tag => tag.trim())
      .filter(tag => tag !== '')

    // If there's input after the last comma, we keep that last bit in the input
    if (
      this.typedInput.slice(-1) !== ',' &&
      (!this.splitSpaces || this.typedInput.slice(-1) !== ' ')
    ) {
      const last = tags.pop()
      this.$input.val(last)
    } else {
      this.resetInput()
    }
    tags.forEach(tag => this.addTag(tag))
    this.renderTags()
  }

  onKeydown(event) {
    const atStart = caretAtStart(this.$input.get(0))
    const selected = this.selectedIndex !== null
    const val = this.$input.val()
    // Adding a new tag from input
    if (val === '' && event.keyCode === ENTER_KEYCODE) {
      // Submit form
    } else if (event.keyCode === ENTER_KEYCODE) {
      event.preventDefault()
      this.addCurrentInput()
      scrollIntoView(this.$input.get(0), this.$inner.get(0))
      // Resetting input when input is focussed
    } else if (event.keyCode === ESCAPE_KEYCODE && !selected) {
      this.resetInput()
      // Delete selected tag
    } else if (
      selected &&
      (event.keyCode === BACKSPACE_KEYCODE || event.keyCode === DELETE_KEYCODE)
    ) {
      event.preventDefault()
      this.removeSelected()
      // Select left from caret or last selected
    } else if (
      (event.keyCode === LEFT_KEYCODE || event.keyCode === BACKSPACE_KEYCODE) &&
      (selected || atStart)
    ) {
      event.preventDefault()
      this.selectLeft()
      // Select right from selected (if any)
    } else if (event.keyCode === RIGHT_KEYCODE && selected) {
      event.preventDefault()
      this.selectRight()
      // Otherwise normal text input
    } else {
      this.selectedIndex = null
      scrollIntoView(this.$input.get(0), this.$inner.get(0))
      this.renderSelected()
    }
  }

  onTypeAheadSelect(_event, value) {
    this.addCurrentInput({
      source: 'awesomplete_wrapper',
      event_element_pos: value.position,
      search_score: value.score
    })
    scrollIntoView(this.$input.get(0), this.$inner.get(0))
  }

  onScroll(_event) {
    this.$wrapper.toggleClass(
      this.overflowLeftClass,
      hasOverflowLeft(this.$inner)
    )
    this.$wrapper.toggleClass(
      this.overflowRightClass,
      hasOverflowRight(this.$inner)
    )
  }

  onSubmit(event) {
    this.addCurrentInput()
    this.renderTags()

    if (!this.skipManualTrack) {
      manualTrack(event.currentTarget, {
        event_element: 'search_input',
        action_type: 'search_new',
        target: this.$hidden.val()
      })
    }
  }

  onTagClick(event) {
    this.selectedIndex = $(event.currentTarget).index()
    this.$input.focus()
    this.renderSelected()
  }

  onTagRemove(event) {
    $(event.currentTarget).parent().click()
    this.removeSelected()
    this.selectedIndex = null
    this.renderSelected()
    return false
  }

  addCurrentInput(opts = {}) {
    const value = this.$input.val().trim()
    if (value === '') {
      return
    }
    this.addTag(value, opts)
    this.resetInput()
    this.renderTags()
  }

  addTag(tag, opts = {}) {
    this.tags.add(tag)
    this.trackTag(tag, opts)
  }

  trackTag(tag, opts = {}) {
    const context =
      opts.source === 'awesomplete_wrapper'
        ? {
            event_element: 'autocomplete',
            target: `${tag}|${this.typedInput}`,
            event_element_pos: opts.event_element_pos,
            search_score: opts.search_score
          }
        : { event_element: 'search_input', target: tag }
    EventBus.trigger('tracking:manualEventTrack', {
      $elem: this.$input,
      action_type: 'pill_add',
      context
    })
  }

  reset() {
    this.tags.clear()
    this.resetInput()
    this.renderTags()
  }

  resetInput() {
    this.$input.val('')
  }

  selectLeft() {
    // No tags left
    if (this.tags.size === 0) {
      this.selectedIndex = null
      // No tag select => select closest to caret
    } else if (this.selectedIndex === null) {
      this.selectedIndex = this.tags.size - 1
      // Select next tag to the left
    } else {
      this.selectedIndex = Math.max(0, this.selectedIndex - 1)
    }
    this.renderSelected()
  }

  selectRight() {
    // Last tag is currently selected, so unselect
    if (this.selectedIndex === this.tags.size - 1) {
      this.selectedIndex = null
      // Otherwise select tag to the right
    } else {
      this.selectedIndex = Math.min(this.tags.size - 1, this.selectedIndex + 1)
    }
    this.renderSelected()
  }

  removeSelected() {
    const selectedTag = [...this.tags][this.selectedIndex]
    if (this.tags.delete(selectedTag)) {
      EventBus.trigger('tracking:manualEventTrack', {
        $elem: this.$input,
        action_type: 'pill_remove',
        context: { event_element: 'search_input', target: selectedTag }
      })
    }
    this.renderTags()
    this.selectLeft()
    this.renderSelected()
  }

  renderSelected() {
    this.$tags.children().removeClass(this.selectedPillClass)

    if (this.selectedIndex !== null) {
      const el = this.$tags
        .children()
        .eq(this.selectedIndex)
        .addClass(this.selectedPillClass)
        .get(0)
      scrollIntoView(el, this.$inner.get(0))
    }
  }

  renderTag(tag) {
    return pillTemplate(
      { key: tag, label: tag },
      'search-input__pill js-search-input__pill'
    )
  }

  renderTags() {
    this.$tags.html([...this.tags].map(this.renderTag).join(''))
    this.$hidden.val([...this.tags].join(','))
  }
}

export const bind = (context = document, retry = 0) => {
  const selector = 'js-search-input'
  const searchElements = $(`.${selector}`, document)

  // the vue world might not be ready yet so this binding has to retry until it finds somthing for the search input selector
  if (searchElements.length === 0 && retry <= 10) {
    setTimeout(() => {
      bind(context, retry + 1)
    }, Math.pow(2, retry))
  } else {
    searchElements.each(function () {
      new SearchInput($(this), selector)
    })
  }
}
