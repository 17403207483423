import debounce from 'lodash-es/debounce'
import UniversalConfig from '@/config/universal'

export default class TypeAhead {
  constructor(awesomplete, url) {
    this.awesomplete = awesomplete
    this.url = url
    this.typeAheadCache = new Map()
    this.lastSearchQuery = ''
    this.query =
      'query ($limit: Int, $jobMarket: JobMarket!, $searchQuery: String!) ' +
      '{ typeAheadWithScore(limit: $limit, jobMarket: $jobMarket, searchQuery: $searchQuery) { synonym, score} }'
  }

  handleTypeAhead(searchQuery, jobMarket) {
    if (searchQuery.length < UniversalConfig.typeAhead.minChars) {
      return Promise.resolve()
    }
    this.lastSearchQuery = searchQuery

    if (this.typeAheadCache.has(searchQuery)) {
      this.awesomplete.list = this.typeAheadCache.get(searchQuery)
      this.awesomplete.evaluate()
      return Promise.resolve()
    } else {
      return this.postTypeAhead({
        searchQuery,
        jobMarket,
        limit: UniversalConfig.typeAhead.maxItems,
        url: this.url
      })
    }
  }

  postTypeAhead = debounce(
    ({ searchQuery, jobMarket, limit, url }) =>
      new Promise(resolve =>
        $.ajax({
          url,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            query: this.query,
            variables: { searchQuery, jobMarket, limit }
          })
        }).done(({ data }) => {
          if (
            searchQuery === this.lastSearchQuery &&
            data.typeAheadWithScore !== null
          ) {
            const mappedList = data.typeAheadWithScore.map((val, index) => {
              return {
                label: val.synonym,
                value: { ...val, position: ++index }
              }
            })
            this.typeAheadCache.set(searchQuery, mappedList)
            this.awesomplete.list = mappedList
            this.awesomplete.evaluate()
          }
          resolve()
        })
      ),
    200,
    {
      leading: true,
      trailing: true
    }
  )
}
