import Awesomplete from 'awesomplete'
import debounce from 'lodash-es/debounce'
import UniversalConfig from '@/config/universal'

export default class AwesompleteWrapper {
  constructor(id) {
    this.input = document.getElementById(id)

    if (!this.input) {
      return
    }

    this.currentSuggestion = null

    this.awesomplete = new Awesomplete(this.input, {
      filter: this.doNotFilter,
      maxItems: UniversalConfig.typeAhead.maxItems,
      minChars: UniversalConfig.typeAhead.minChars,
      sort: false,
      replace: function (suggestion) {
        this.input.value = suggestion.label
      }
    })

    this.input.addEventListener('awesomplete-highlight', event => {
      this.currentSuggestion = event.text
      this.awesomplete.replace(event.text)
    })

    this.input.addEventListener('awesomplete-selectcomplete', event => {
      EventBus.trigger('awesomplete_wrapper:select', event.text.value)
    })

    this.input.addEventListener('click', () => {
      this.awesomplete.evaluate()
    })

    this.input.addEventListener(
      'focus',
      debounce(() => {
        this.awesomplete.evaluate()
      })
    )
  }

  doNotFilter(text, input) {
    return true
  }
}
