export default class ReactiveLoginLink {
  constructor($el) {
    this.$el = $el
    this.addEventListeners()
  }

  addEventListeners() {
    EventBus.on('user:loggedIn', this.onUserLoggedIn.bind(this))
  }

  onUserLoggedIn(_event) {
    this.$el.find('.js-logged-in').show()
    this.$el.find('.js-logged-out').hide()
  }
}
