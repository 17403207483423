// TODO refactor (copied from old pattern library)

export default function scrollTop(scrollButton) {
  let scrollTimeout
  let hideTimeout
  let currentScrollY
  let lastScrollY
  let isScrolling = false
  const localScrollButton = scrollButton
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  const easingFunction = function easingFunction(t) {
    const ease = Math.pow(t - 1, 3) + 1
    return ease
  }

  function prepareScroll() {
    if (!isScrolling) {
      isScrolling = true

      if (scrollTimeout) {
        clearTimeout(scrollTimeout)
      }
      scrollTimeout = setTimeout(onScroll(), 75)
    }
  }

  function onScroll() {
    currentScrollY =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0)

    // Adjust sensibility on touch devices.
    if (Math.abs(currentScrollY - lastScrollY) > 5) {
      if (lastScrollY > currentScrollY && windowHeight * 1 < currentScrollY) {
        // Scroll up.
        showButton()
      } else {
        // Scroll down.
        hideButton()
      }
    }
    lastScrollY = currentScrollY
    isScrolling = false
  }

  function showButton() {
    localScrollButton.classList.add('is-scrolling')
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }
    hideTimeout = setTimeout(hideButton, 4000)
  }

  function hideButton() {
    localScrollButton.classList.remove('is-scrolling')
  }

  function scrollTo(target, duration) {
    let base = document.documentElement
    let currentPos = base.scrollTop
    let position = target
    const maxScroll = base.scrollHeight - base.offsetHeight

    if (currentPos === 0) {
      base.scrollTop += 1
      if (currentPos + 1 !== base.scrollTop - 1) {
        base = document.body
      }
      currentPos = base.scrollTop
    }

    if (typeof target === 'object' && target.offsetTop) {
      position = target.offsetTop - 20
    }

    // You cannot scroll further than the page height.
    if (maxScroll < position) {
      position = maxScroll
    }

    if (duration < 16) {
      base.scrollTop = position
      return
    }

    scrollStep(base, currentPos, position, 0, duration)
  }

  function scrollStep(base, from, to, startTime, duration) {
    const current = new Date().getTime()
    const localBase = base
    let localStartTime = startTime

    if (localStartTime === 0) {
      localStartTime = current
    }

    const currentState = (current - localStartTime) / duration

    if (currentState > 1) {
      localBase.scrollTop = to
      return
    }

    // Update scroll position and current process of the whole animation.
    localBase.scrollTop = getNewScrollPosition(from, to, currentState)

    // Register timeout for next animation step.
    window.requestAnimationFrame(() => {
      scrollStep(localBase, from, to, localStartTime, duration)
    })
  }

  function getNewScrollPosition(from, to, currentState) {
    const pos = from - (from - to) * easingFunction(currentState)
    return pos
  }

  function setup() {
    window.addEventListener(
      'touchstart',
      () => {
        setTimeout(onScroll(), 50)
      },
      false
    )
    window.addEventListener('scroll', prepareScroll, false)
    localScrollButton.addEventListener(
      'click',
      () => {
        scrollTo(0, 1000)
      },
      false
    )
  }

  setTimeout(setup, 100)

  // expose public api
  return {
    scrollTo
  }
}

$(() => {
  const scrollButton = document.querySelector('.js_scrolltop')
  // fsd app no longer uses this scrollButton, check if scrollButton exists for legacy code
  if (scrollButton) {
    scrollTop(scrollButton)
  }
})
