export default class AllTag {
  constructor($el, tagSelector, eventNameSpace) {
    this.$el = $el
    this.tagSelector = tagSelector
    this.eventNameSpace = eventNameSpace
    this.allTags = this.findAllTags()
    this.activeTags = []
    this.addEventListeners()
  }

  findAllTags() {
    return this.$el
      .parent()
      .siblings()
      .find(this.tagSelector)
      .map(function () {
        return {
          key: $(this).attr('value'),
          label: $(this).data('label')
        }
      })
      .get()
  }

  addEventListeners() {
    EventBus.on(`${this.eventNameSpace}:add`, this.onAdd.bind(this))
    EventBus.on(`${this.eventNameSpace}:remove`, this.onRemove.bind(this))
    this.$el.on('click', this.onClick.bind(this))
  }

  onAdd(_event, data) {
    if (
      this.findTag(this.allTags, data.key) !== -1 &&
      this.activeTags.indexOf(data.key) === -1
    ) {
      this.activeTags.push(data.key)
      this.render()
    }
  }

  onClick(_event) {
    const kind = this.isChecked() ? 'remove' : 'add'
    this.allTags.forEach(tag => {
      setTimeout(() => {
        EventBus.trigger(`${this.eventNameSpace}:${kind}`, tag)
      })
    })
  }

  onRemove(_event, data) {
    const index = this.activeTags.indexOf(data.key)
    if (this.findTag(this.allTags, data.key) !== -1 && index !== -1) {
      this.activeTags.splice(index, 1)
      this.render()
    }
  }

  isChecked() {
    return this.allTags.length === this.activeTags.length
  }

  findTag(tagList, key) {
    return tagList.findIndex(tag => tag.key === key)
  }

  render() {
    this.$el.prop('checked', this.isChecked())
  }
}
