const FADE_SPEED = 300

export default class Modal {
  // Basic markup
  // <a class="js-open-modal">open the modal</a>

  // <div class="modal">
  //   <div class="modal--content"">
  //     <p class="modal--content__headline">are you sure?</p>
  //     <a href="#" class="button modal--content__button">CTA</a>
  //     <a href="#" class="modal__close">close</a>
  //   </div>
  // </div>

  constructor(element) {
    // the element that triggers opening the modal
    this.element = element

    // the grey overlay behind the content
    this.modal = $('.modal')
    this.content = $('.modal--content', this.modal)
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.element.on('click', this.element, this.open.bind(this))
    this.modal.on('click', '.modal--content__close', this.onClose.bind(this))
  }

  onClose(event) {
    event.preventDefault()
    this.modal.fadeOut(FADE_SPEED)
  }

  open(event) {
    event.preventDefault()
    this.modal.fadeIn(FADE_SPEED)
    // we need to call center modal after fading, otherwise the height and width are computedd to 0
    this.centerModal()
  }

  centerModal() {
    const topOffset =
      this.content.css('top') === '0px' ? 0 : -(this.content.outerHeight() / 2)
    const leftOffset =
      this.content.css('left') === '0px' ? 0 : -(this.content.outerWidth() / 2)
    this.content.css({
      marginTop: topOffset,
      marginLeft: leftOffset,
      zIndex: 2
    })
  }
}
