import { updateRegistrationTeasers } from '@/registration_teaser/bind'
import debounce from 'lodash-es/debounce'

export default class BookmarkRemove {
  constructor($el) {
    this.$el = $el
    this.jobId = parseInt(this.$el.data('jobId'))
    this.title = this.$el.data('title')
    this.skipAnimation = this.$el.data('skip-animation')
    this.addEventListeners()
  }

  addEventListeners() {
    if (!this.skipAnimation) {
      EventBus.on('bookmarks:removed', this.onEventBookmarksRemoved.bind(this))
    }
    EventBus.on('bookmarks:added', this.onEventBookmarksAdded.bind(this))
    // the bookmark tag is 'within' an anchor tag, thus preventDefault to prevent the opening of the link
    // preventDefault has to happen 'outside' of debounce, thus two separate click events have to be registered
    this.$el.on('click', function (e) {
      e.preventDefault()
    })
    this.$el.on(
      'click',
      debounce(this.onClickBookmarkRemove.bind(this), 300, {
        leading: true,
        trailing: false
      })
    )
  }

  onClickBookmarkRemove(e) {
    this.removeBookmark(this.jobId)
  }

  onEventBookmarksAdded(_event, { jobId }) {
    if (jobId === this.jobId) {
      return this.$el.addClass('bookmark-flag__toggle-label--active')
    }
  }

  onEventBookmarksRemoved(_event, { jobId }) {
    if (jobId === this.jobId) {
      return this.$el.removeClass('bookmark-flag__toggle-label--active')
    }
  }

  onUndo(_event) {
    EventBus.trigger('bookmarks:add', this.jobId)
  }

  removeBookmark(jobId) {
    $.ajax({
      url: this.$el.data('url'),
      data: {
        title: this.title,
        refresh_registration_teasers:
          $('.js-dynamic-registration-teaser').length > 0
      },
      method: 'delete',
      dataType: 'json'
    })
      .done(({ snackbar, teaser, searchContext, bookmarkCount }) => {
        updateRegistrationTeasers(teaser)
        EventBus.trigger('bookmarks:removed', {
          jobId,
          searchContext
        })
        EventBus.trigger('bookmarks:count', bookmarkCount)
        EventBus.trigger('snackbar:message', {
          body: snackbar,
          handlers: [
            {
              selector: '.js-snackbar__button',
              eventName: 'click',
              handler: this.onUndo.bind(this)
            }
          ]
        })
      })
      .fail(response => {
        if ([401, 403].includes(response.status)) {
          EventBus.trigger('sessionModal:open', {
            intent: 'toggleBookmark',
            intentData: jobId
          })
        }
      })
  }
}
