import { waitForSourcePointConsent } from '@/util/consent'

export default class MedRec {
  constructor($element) {
    this.$element = $element
    this.iframe = $('iframe', this.$element)[0]

    this.adUrl = $element.data('ad-url') || ''

    if (this.adUrl && this.iframe) {
      waitForSourcePointConsent().then(this.addAd.bind(this))
    }
  }

  addAd() {
    this.adUrl = this.adUrl.replace(
      'GDPR_CONSENT_39',
      global.window.acConsent.tcString
    )
    const tag = document.createElement('script')
    tag.setAttribute('type', 'text/javascript')
    tag.setAttribute('src', this.adUrl)

    // browsers add 8px margin to <body> in iframe
    this.iframe.style.margin = '-8px'
    this.iframe.contentWindow.document.write(tag.outerHTML)

    // med rec dimensions + margins
    this.iframe.width = 316
    this.iframe.height = 268
  }
}

$(() => {
  $('.js-widgets-med-rec').each((_index, Element) => {
    new MedRec($(Element))
  })
})
