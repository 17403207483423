import { updateRegistrationTeasers } from '@/registration_teaser/bind'
import debounce from 'lodash-es/debounce'

export default class BookmarkAdd {
  constructor($el) {
    this.$el = $el
    this.jobId = parseInt(this.$el.data('jobId'))
    this.title = this.$el.data('title')
    this.addEventListeners()
  }

  addEventListeners() {
    // the bookmark tag is 'within' an anchor tag, thus preventDefault to prevent the opening of the link
    // preventDefault has to happen 'outside' of debounce, thus two separate click events have to be registered
    this.$el.on('click', function (e) {
      e.preventDefault()
    })
    this.$el.on(
      'click',
      debounce(this.onClickAddBookmark.bind(this), 300, {
        leading: true,
        trailing: false
      })
    )
    EventBus.on('bookmarks:add', this.onEventBookmarksAdd.bind(this))
  }

  onClickAddBookmark(e) {
    this.addBookmark(this.jobId)
  }

  onEventBookmarksAdd(_event, jobId) {
    if (this.jobId === jobId) {
      this.addBookmark(jobId)
    }
  }

  addBookmark(jobId) {
    $.ajax({
      url: this.$el.data().url,
      data: {
        title: this.title,
        refresh_registration_teasers:
          $('.js-dynamic-registration-teaser').length > 0
      },
      method: 'put',
      dataType: 'json'
    })
      .done(({ snackbar, teaser, searchContext, bookmarkCount }) => {
        updateRegistrationTeasers(teaser)
        EventBus.trigger('bookmarks:added', {
          jobId,
          searchContext
        })
        EventBus.trigger('bookmarks:count', bookmarkCount)
        EventBus.trigger('snackbar:message', {
          body: snackbar,
          handlers: []
        })
      })
      .fail(response => {
        if ([401, 403].includes(response.status)) {
          EventBus.trigger('sessionModal:open', {
            intent: 'toggleBookmark',
            intentData: jobId
          })
        }
      })
  }
}
