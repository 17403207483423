import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'

MediaQuery._init()

const $jobResponsiveAd = $('.js-job-ad-responsive .jw-wrapper-responsive')

const $jobScaleAd = $('.js-job-ad-scaling > div')
const jobAdWidth = $jobScaleAd.outerWidth()

const $wrapper = $('.js-job-ad-scaling')
const wrapperWidth = $wrapper.innerWidth()

const _scaleAd = function () {
  const jobAdHeight = $jobScaleAd.outerHeight()
  let scale = wrapperWidth / jobAdWidth
  if (wrapperWidth <= 375 && scale >= 1) {
    scale = 0.7
  }

  $jobScaleAd.css('transform', 'scale(' + scale + ')')
  $wrapper.css('height', jobAdHeight * scale)
}

const _responsiveAd = () => {
  const current = `jw-${MediaQuery.current}`
  $jobResponsiveAd.removeClass(
    'jw-small jw-medium jw-large'.replace(current, '')
  )
  $jobResponsiveAd.addClass(current)
}

$(document).ready(function () {
  $jobScaleAd.addClass('job-ad__jobware')
  _scaleAd()
  _responsiveAd()
})

$(window).resize(_scaleAd)
$(window).resize(_responsiveAd)
