export default class BookmarkLoader {
  constructor($el) {
    this.jobs = []
    this.addEventListeners()
    if ($el.data('signedIn')) {
      this.fetchData($el.data('bookmarksUrl'))
    }
  }

  addEventListeners() {
    EventBus.on('bookmarks:added', this.onBookmarkAdded.bind(this))
    EventBus.on('bookmarks:removed', this.onBookmarkRemoved.bind(this))
    EventBus.on('bookmarks:replay', this.onBookmarkReplay.bind(this))
  }

  onBookmarkAdded(_event, { jobId }) {
    if (this.jobs.indexOf(jobId) < 0) {
      this.jobs.push(jobId)
    }
  }

  onBookmarkRemoved(_event, { jobId }) {
    const index = this.jobs.indexOf(jobId)
    if (index >= 0) {
      this.jobs.splice(index, 1)
    }
  }

  onBookmarkReplay(_event) {
    this.jobs.forEach(jobId => {
      EventBus.trigger('bookmarks:added', { jobId })
    })
  }

  fetchData(url) {
    const triggerBookmarksAdded = this.triggerBookmarksAdded.bind(this)
    return $.ajax({
      type: 'GET',
      url
    }).done(data => triggerBookmarksAdded(data))
  }

  triggerBookmarksAdded(jobs) {
    this.jobs = jobs
    const results = []
    for (let i = 0, len = jobs.length; i < len; i++) {
      const jobId = jobs[i]
      results.push(EventBus.trigger('bookmarks:added', { jobId }))
    }
    EventBus.trigger('bookmarks:count', this.jobs.length)
    return results
  }
}
