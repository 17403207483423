class EmailChange {
  constructor($el) {
    this.init($el)
  }

  init($el) {
    this.$el = $el
    this.$form = $el.find('.js-email-change-form')
    this.$input = $el.find('.js-email-change-input')
    this.$submit = $el.find('.js-email-change-submit')
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.$submit.on('click', event => {
      event.preventDefault()
      this.onSubmit()
    })
  }

  swapElement(html) {
    const $elem = $(html).hide()
    this.$el.fadeOut(() => {
      this.$el.replaceWith($elem)
      this.init($elem)
      this.$el.fadeIn()
    })
  }

  onSubmit() {
    $.ajax({
      method: 'POST',
      url: this.$form.attr('action'),
      data: { email: this.$input.val() },
      dataType: 'json'
    }).success(response => {
      this.swapElement(response.body)
    })
  }
}

export const bind = ($context = document) => {
  $(() => {
    $('#account-details__email-change', $context).each(function (
      _index,
      value
    ) {
      new EmailChange($(value))
    })
  })
}

$(() => {
  bind()
})
