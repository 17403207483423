import OffCanvas from './off_canvas'

$(() => {
  new OffCanvas(
    $('.js-layout'),
    $('.js-layout__off-canvas'),
    $('.js-layout__page'),
    $('.js-toggle-layout')
  )
})
