import debounce from 'lodash-es/debounce'

const FADE_SPEED = 300

export default class ToggleSearchProfile {
  constructor($el, selectorPrefix) {
    this.$el = $el
    this.url = this.$el.data('toggle-url')
    this.name = this.$el.data('title')
    this.isActive = this.$el.data('active')
    this.profile = null
    this.$toggle = this.$el.find(`${selectorPrefix}__toggle`)
    this.$label = this.$el.find(`${selectorPrefix}__label`)
    this.labels = {
      active: this.$el.data('toggle-active-label'),
      inactive: this.$el.data('toggle-inactive-label')
    }
    this.$label.text(this.labels[this.isActive ? 'active' : 'inactive'])
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.$toggle.on(
      'change',
      debounce(() => {
        this.synchronizeWithInput(this)
      }, 300)
    )

    EventBus.on('search-profiles:bulk-update-state', (_event, isActive) => {
      this.synchronizeWithValue(isActive)
    })
  }

  synchronizeWithInput() {
    this.isActive = this.$toggle.prop('checked')
    this.updateProfile()
    this.updateElement()
    EventBus.trigger('search-profile:update-state', null)
  }

  synchronizeWithValue(isActive) {
    this.isActive = isActive
    this.$toggle.prop('checked', this.isActive)
    this.updateElement()
  }

  updateProfile() {
    $.ajax({
      dataType: 'json',
      url: this.url,
      method: 'post',
      data: {
        name: this.name,
        is_active: this.isActive
      }
    })
  }

  updateElement() {
    this.$el.data('active', this.isActive)
    const currentLable = this.labels[this.isActive ? 'active' : 'inactive']
    if (this.$label.text() !== currentLable) {
      this.$label.fadeOut(FADE_SPEED, () => {
        this.$label.text(currentLable)
        this.$label.fadeIn(FADE_SPEED)
      })
    }
  }
}
