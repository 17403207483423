import { registerGoogleTagManagerEvent } from '@/gtm_tracking/events'

export default class RegistrationTeaser {
  constructor($el, index) {
    this.$el = $el
    this.$index = index
    this.$categories = $el.find('.js-registration-categories')
    this.$errorHint = $el.find('.js-registration-errorHint')
    this.$fader = $el.find('.js-registration-fader')
    this.$form = $el.find('.js-registration-form')
    this.$input = $el.find('.js-registration-input')
    this.$submit = $el.find('.js-registration-submit')
    this.$icon = $el.find('.js-registration-toggle-icon')
    this.addEventHandlers()
  }

  addEventHandlers() {
    this.$fader.click(this.onClick.bind(this))

    EventBus.on(`registration:submit${this.$index}`, this.onSubmit.bind(this))
    this.$submit.on('click', event => {
      event.preventDefault()
      EventBus.trigger(`registration:submit${this.$index}`, null)
    })
  }

  onClick() {
    $(this.$categories).toggleClass(
      'registration-teaser__category-tags--expanded'
    )
    $(this.$icon).toggleClass('registration-teaser__toggle-icon--close')
  }

  onSubmit() {
    $.ajax({
      method: 'POST',
      url: this.$form.attr('action'),
      data: this.$form.serialize(),
      dataType: 'json'
    })
      .success(response => {
        if (response.show_snackbar === true) {
          EventBus.trigger('snackbar:message', {
            body: response.body,
            handlers: []
          })
          $(this.$input).val('')
        } else {
          $(this.$el).fadeOut()
          $(this.$el).replaceWith(response.body)
          $(this.$el).fadeIn()
        }
        EventBus.trigger('tracking:registrationSuccess', {
          pixelsConfig: response.webtrekk
        })
        registerGoogleTagManagerEvent('registrationSuccess')
      })
      .error(() => {
        $(this.$input).addClass('input--invalid')
        $(this.$errorHint).addClass('registration-teaser__error-hint--visible')
      })
  }
}

export const bind = ($context = document) => {
  $(() => {
    $.each($('.js-registration-teaser'), function (index, value) {
      new RegistrationTeaser($(value), index)
    })
  })
}

export const updateRegistrationTeasers = teaser => {
  if (teaser !== undefined && teaser !== '') {
    $('.js-dynamic-registration-teaser').html(teaser)
    $.each($('.js-registration-teaser'), function (index, value) {
      new RegistrationTeaser($(value), index)
    })
  }
}
