export default class AdblockFence {
  constructor($el, fenceSelector) {
    this.$el = $el
    this.fenceSelector = fenceSelector
    EventBus.on('adblockFence:open', this.onOpen.bind(this))
    this.$el.on(
      'click',
      `.${this.fenceSelector}-close`,
      this.onClose.bind(this)
    )
    this.$el.on(
      'click',
      `.${this.fenceSelector}-overlay`,
      this.onOverlayClick.bind(this)
    )
  }

  onOpen() {
    this.$el.fadeIn()
  }

  onClose() {
    this.$el.fadeOut()
  }

  onOverlayClick($event) {
    if ($event.currentTarget === $event.target) {
      $(`.${this.fenceSelector}-close`, this.$el).click()
    }
  }
}
