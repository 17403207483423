import debounce from 'lodash-es/debounce'

const SNACKBAR_TIMEOUT = 7000
const HOVER_TIMEOUT = 4000
const SNACKBAR_DEBOUNCE = 300
const SNACKBAR_BODY = '.js-snackbar__body'

export default class Snackbar {
  constructor($el) {
    this.$el = $el
    this.markup = null
    this.isSmall = false
    this.timeout = null
    this.handlers = []
    this.addEventListeners()
  }

  addEventListeners() {
    EventBus.on(
      'snackbar:message',
      debounce(this.onMessage.bind(this), SNACKBAR_DEBOUNCE)
    )
    EventBus.on('snackbar:hide', this.onHide.bind(this))
  }

  onMessage(_event, { body, handlers, small }) {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.onTimeout()
      setTimeout(() => {
        this.onMessage(_event, { body, handlers, small })
      }, SNACKBAR_DEBOUNCE)
    } else {
      this.markup = body
      this.handlers = handlers
      this.isSmall = !!small
      this.render()
      this.timeout = setTimeout(this.onTimeout.bind(this), SNACKBAR_TIMEOUT)
    }
  }

  onHide(_event, _data) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.onTimeout()
  }

  onMouseEnter() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  onMouseLeave() {
    this.timeout = setTimeout(this.onTimeout.bind(this), HOVER_TIMEOUT)
  }

  onClose(event) {
    event.preventDefault()
    this.onHide()
  }

  onTimeout() {
    this.markup = null
    this.handlers = []
    this.timeout = null
    this.render()
  }

  render() {
    if (this.markup) {
      this.$el.find(SNACKBAR_BODY).html(this.markup)
    }
    this.$el.toggleClass('snackbar--active', !!this.markup)
    this.$el.toggleClass('snackbar--small', this.isSmall)
    this.$el.off()
    this.$el.on('click', '.js-snackbar__close', this.onClose.bind(this))
    this.$el.on('mouseenter', this.onMouseEnter.bind(this))
    this.$el.on('mouseleave', this.onMouseLeave.bind(this))
    for (const { selector, handler, eventName } of this.handlers) {
      this.$el.on(eventName, selector, handler)
    }
  }
}

export const bind = ($doc = document) => {
  new Snackbar($('.js-snackbar', $doc))
}

bind()
