/* eslint no-console:0 */

import jQuery from 'jquery'
import jqueryUjsInit from 'jquery-ujs'

import 'core-js/stable'
import 'intersection-observer'
import 'regenerator-runtime/runtime'
// IE11 compatibility
import 'custom-event-polyfill'
import '@ungap/global-this'
import 'loading-attribute-polyfill'

import './config/universal'
import './event_bus'
import './consent_listener'

import './adblock_fence'
import './athenex'
import './bookmarks'
import './email_change'
import './google_maps'
import './ivw_tracking'
import './job_ad'
import './layout'
import './login'
import './med_rec'
import './password'
import './popstate_fix'
import './registration_fence'
import './registration_teaser'
import './scroll_to_top'
import './search_profiles'
import './search/bind'
import './search/toggle'
import './snackbar'
import './social_feed'
import './toggle'
import './vwo'
import './webtrekk_tracking'
import './widgets'
import './youtube_video'

global.$ = global.jQuery = jQuery
jqueryUjsInit(jQuery)
