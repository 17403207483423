export default class PasswordVisibleSwitch {
  constructor($switch, $passwordInput) {
    this.$el = $switch
    this.$passwordInput = $passwordInput
    this.addEventListeners()
  }

  addEventListeners() {
    this.$el.on('click', this.toggleVisible.bind(this))
  }

  toggleVisible(_event) {
    this.$el.toggleClass('hidden')
    const type =
      this.$passwordInput.attr('type') === 'password' ? 'text' : 'password'
    this.$passwordInput.attr('type', type)
  }
}
