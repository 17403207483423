import Subnavigation from './subnavigation'

export const bindAll = $doc => {
  if ($doc.find('.js-subnavigation').length > 0) {
    $doc.find('.js-subnavigation').each(function () {
      new Subnavigation($(this), $doc.find('.js-subnavigation-headlines h2'))
    })
  }
  if ($doc.find('.js-image-gallery').length > 0) {
    // Using webpacks lazy loading https://webpack.js.org/guides/lazy-loading/ so we don't include slick in our main
    // asset bundle.
    import('./image_gallery').then(({ default: ImageGallery }) => {
      ImageGallery()
    })
  }
}

$(() => {
  bindAll($(document))
})
