export default class PasswordCheckAtLeast {
  constructor($el, $passwordInput) {
    this.$el = $el
    this.$passwordInput = $passwordInput
    this.addEventListeners()
  }

  addEventListeners() {
    this.$passwordInput.on('input', this.onPasswordCheckAtLeast.bind(this))
  }

  onPasswordCheckAtLeast(_event) {
    if (this.$passwordInput.val().length >= 8) {
      this.$el.addClass('card__password-hint--checked')
    } else {
      this.$el.removeClass('card__password-hint--checked')
    }
  }
}
