import { ENTER_KEYCODE, ESCAPE_KEYCODE, COMMA_KEYCODE } from '@/util/keycodes'

export default class SearchTerms {
  constructor($el, selectorPrefix, eventNameSpace) {
    this.$el = $el
    this.$input = this.$el.find(`${selectorPrefix}__input`)
    this.$searchTerms = this.$el.find(`${selectorPrefix}__search-terms`)

    this.$done = this.$el.find(`${selectorPrefix}__edit-done`)
    this.$abort = this.$el.find(`${selectorPrefix}__edit-abort`)

    this.$editing = this.$el.find(`${selectorPrefix}__editing`)
    this.$notEditing = this.$el.find(`${selectorPrefix}__not-editing`)

    this.$startEdit = this.$el.find(`${selectorPrefix}__start-edit`)

    this.searchTerms = new Set()
    this.eventNameSpace = eventNameSpace
    this.initSearchTerms()
    this.addEventHandlers()
  }

  addEventHandlers() {
    EventBus.on(
      `${this.eventNameSpace}:search-terms:start-edit`,
      this.onStartEdit.bind(this)
    )
    EventBus.on(
      `${this.eventNameSpace}:search-terms:stop-edit`,
      this.onStopEdit.bind(this)
    )
    EventBus.on(`${this.eventNameSpace}:remove`, this.onRemove.bind(this))

    this.$done.on('click', this.onDone.bind(this))
    this.$abort.on('click', this.onAbort.bind(this))

    this.$startEdit.on('click', event => {
      event.preventDefault()
      EventBus.trigger(`${this.eventNameSpace}:search-terms:start-edit`, null)
    })

    this.$input.on('keydown', this.onKeypress.bind(this))
  }

  initSearchTerms() {
    this.$searchTerms
      .val()
      .split(',')
      .forEach(elem => {
        this.addNewTerm(elem)
      })
  }

  addCurrentTerm() {
    this.addNewTerm(this.$input.val())
    this.$input.val('')
  }

  addNewTerm(term) {
    const val = term.trim()

    if (val !== '') {
      this.searchTerms.add(val)
      EventBus.trigger(`${this.eventNameSpace}:add`, {
        label: val,
        key: val,
        type: 'search-term'
      })
      this.render()
    }
  }

  onAbort(event) {
    event.preventDefault()
    this.resetClose()
  }

  onDone(event) {
    event.preventDefault()
    this.addCurrentTerm()
    this.resetClose()
  }

  onKeypress(event) {
    if (event.keyCode === ENTER_KEYCODE || event.keyCode === COMMA_KEYCODE) {
      event.preventDefault()
      this.addCurrentTerm()
    }

    if (event.keyCode === ENTER_KEYCODE || event.keyCode === ESCAPE_KEYCODE) {
      this.resetClose()
    }
  }

  onRemove(_event, data) {
    this.searchTerms.delete(data.key)
    this.render()
  }

  onStartEdit() {
    this.$editing.show()
    this.$notEditing.hide()
    this.$input.focus()
  }

  onStopEdit() {
    this.$notEditing.show()
    this.$editing.hide()
  }

  resetClose() {
    this.$input.val('')
    EventBus.trigger(`${this.eventNameSpace}:search-terms:stop-edit`, null)
  }

  render() {
    this.$searchTerms.val([...this.searchTerms].join(','))
  }
}
