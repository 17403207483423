import BookmarkAdd from '@/bookmarks/bookmark_add'
import BookmarkCounter from '@/bookmarks/bookmark_counter'
import BookmarkIcon from '@/bookmarks/bookmark_icon'
import BookmarkItem from '@/bookmarks/bookmark_item'
import BookmarkListEmpty from '@/bookmarks/bookmark_list_empty'
import BookmarkLoader from '@/bookmarks/bookmark_loader'
import BookmarkRemove from '@/bookmarks/bookmark_remove'

export default ($context = document) => {
  $(() => {
    $('.js-bookmark-add', $context).each(function () {
      new BookmarkAdd($(this))
    })

    $('.js-bookmark-list-empty', $context).each(function () {
      new BookmarkListEmpty($(this))
    })

    $('.js-bookmarks-total', $context).each(function () {
      new BookmarkCounter($(this))
    })

    $('.js-bookmark-remove', $context).each(function () {
      new BookmarkRemove($(this))
    })

    $('.js-bookmark-item', $context).each(function () {
      new BookmarkItem($(this))
    })

    $('.js-bookmarks-loader', $context).each(function () {
      new BookmarkLoader($(this))
    })

    $('.js-bookmarks-icon', $context).each(function () {
      new BookmarkIcon($(this))
    })
  })
}
