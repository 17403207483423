import escape from 'lodash-es/escape'

export default (tag, classes) =>
  `<span class="button pill with-icon ${classes}" data-key="${escape(tag.key)}">
  <span class="text-overflow">${escape(tag.label)}</span>
  <span class="pill__delete js-search-input__remove">
    <svg class="icon icon--small">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icons-close"></use>
    </svg>
  </span>
</span>`
